module services {
    export module master {
        export class rejectionService implements interfaces.master.IRejectionService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all rejections for a specific type and current entity
            getDropdownList(typeClassificationValueId:number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Rejection/GetRejectionsForDropdown", {
                    typeClassificationValueId: typeClassificationValueId
                });
            }

            //Gets list of all rejections for a specific type and specified entity
            getDropdownListByOwnerEntityId(typeClassificationValueId: number, ownerEntityId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Rejection/GetDropdownListByOwnerEntityId", {
                    typeClassificationValueId: typeClassificationValueId,
                    ownerEntityId: ownerEntityId
                });
            }
        }
    }
    angular.module("app").service("rejectionService", services.master.rejectionService);
}